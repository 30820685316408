export const scent = [ 

    {
        source: './sam/scan1.webp'
    },

    {
        source: './sam/scan3.webp'
    },

    {
        source: './sam/text1.webp'
    },

    {
        source: './sam/text2.webp'
    },

];