export const projects = [  
  {  
    title: "A History of My Personal Website",
    description: "Change over time",
    year: '2018 - forever',
    type: 'Web Design and Development',
    context: 'personal',
    link: '/website',
    cover: './img/covers/website.webp',
    target: '_self',
    case: false,

  },

  {  
    title: "Scent Access Memory",
    description: "Imagery for Are.na x Dirt",
    year: '2024',
    type: 'Illustration',
    context: 'client',
    link: '/scent',
    cover: './img/covers/scent.png',
    target: '_self',
    case: false,
  },


    {  
    title: "The Pleasure x Effort Matrix",
    description: "Notes on the hypothetical self",
    year: '2024',
    type: 'Writing',
    context: 'Client',
    link: 'https://syllabusproject.org/the-pleasure-x-effort-matrix/',
    cover: './img/covers/syllabus.webp',
    target: '_blank',
    case: false,

  },

  {  
    title: "The Memo, New York Times Advertising",
    description: "Recap of the 2023 DealBook Summit",
    year: '2023',
    type: 'Web Design and Development',
    context: 'client',
    link: 'https://advertising.nytimes.com/thememo/',
    cover: './img/covers/nyt.webp',
    target: '_blank',
    case: false,
  },


  {  
    title: "Flowers in Motion",
    description: "A radio show",
    year: '2023',
    type: 'Music, Design',
    context: 'personal',
    link: '/flowers',
    cover: './img/covers/flowers.webp',
    target: '_self',
    case: false,
  },


  {  
    title: 'Playlist Prompts',
    description: "A collection of playlist ideas",
    year: '2022',
    type: 'Web Design and Development',
    context: 'personal',
    link: 'https://www.playlistprompts.com/',
    cover: './img/covers/playlist.webp',
    target: '_blank',
    case: false,
  },


  {  
    title: "Sunny Place for Shady People",
    description: "A Western-inspired visualizer",
    year: '2021',
    type: 'Animation',
    context: 'client',
    link: 'https://www.youtube.com/watch?v=sW4aexJe8-s',
    cover: './img/covers/sunny.webp',
    target: '_blank',    
    case: false,

  },

  {  
    title: "Blue Flower Arts",
    description: "Brand identity for a boutique literary agency",
    year: '2021',
    type: 'Brand Design',
    context: 'client',
    link: '/bfa',
    cover: './img/covers/bfa.webp',
    target: '_self',
    case: false,

  },

  // {  
  //   title: "Student Visa Review",
  //   description: "An online exhibition of interviews with architecture students",
  //   year: '2021',
  //   type: 'Web Design',
  //   context: 'client',
  //   link: '/student-visa',
  //   cover: './img/covers/risd.webp',
  //   target: '_self',
  //   case: false,
  // },

  {  
    title: "Two People in a Room Over Time",
    description: "A series of Risograph prints",
    year: '2019',
    type: 'Illustration',
    context: 'personal',
    link: '/people',
    cover: './img/covers/people.webp',
    target: '_self',
    case: false,

  },

  {  
    title: "I Didn't Ask for the Habit",
    description: "An abstract comic",
    year: '2018',
    type: 'Illustration',
    context: 'personal',
    link: '/habit',
    cover: './img/covers/habit.webp',
    target: '_self',
    case: false,
  },

  {  
    title: "Ink Drawings",
    description: "Sketchbook scans",
    year: '2021 - present',
    type: 'Illustration',
    context: 'personal',
    link: '/sketch',
    cover: './img/covers/sketch-2.webp',
    target: '_self',
    case: false,
  },

  {  
    title: "Digital Drawings",
    description: "Experiments with 3D software, screenshots, and Photoshop",
    year: '2018 - 2020',
    type: 'Animation, Illustration',
    context: 'personal',
    link: '/sketch-digital',
    cover: './img/covers/sketch.webp',
    target: '_self',
    case: false,
  },

 

];